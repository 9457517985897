html{
  font-size: 14px;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-track{
  background: #f2f2f2;
}

::-webkit-scrollbar-thumb {
  background:  #d3d2d3;
  border-radius: 20px;       /* roundness of the scroll thumb */
}




