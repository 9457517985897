.nemap-container {
	/* height: 380px; */
	width: 100%;
	margin: 0 auto;
}
.loading {
	margin-top: 10em;
	text-align: center;
	color: gray;
}

.chart-container {
	/* margin-top: 30px; */
	padding: 20px;
	padding-top: 10px;
}

.spaceBWDiv {
	width: 93%;
	display: flex;
	flex-direction: row;
	padding: 0.3rem 1.5rem;
	justify-content: space-between;
}
